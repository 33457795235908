import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { cx } from "emotion"
import { Space } from "../styles"

const PrivacyPage = () => (
  <Layout hasCta={false}>
    <SEO title="Privacy" />
    <div className={cx(Space.section)}>
      <h3>Privacy Policy</h3>
      <p>Lorem ipsum...</p>
    </div>
  </Layout>
)

export default PrivacyPage
